// components/ClipboardButton.tsx
import { useState } from "preact/hooks";

interface ClipboardButtonProps {
  text: string;
}

// ClipboardButton component to copy text to clipboard
export default function ClipboardButton({ text }: ClipboardButtonProps) {
  const [isBouncing, setIsBouncing] = useState(false);

  // Function to copy text to clipboard and trigger animation
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setIsBouncing(true);
    setTimeout(() => setIsBouncing(false), 500); // Trigger animation for 500ms
  };

  return (
    <button
      class={`absolute right-2.5 top-2.5 text-gray-400 hover:text-gray-600 ${
        isBouncing ? "animate-bounce-pulse" : ""
      }`}
      onClick={copyToClipboard}
      title="Copy to clipboard"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="orange"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H8zM6 6h12M6 10h12"
        />
      </svg>
    </button>
  );
}
